export const api = {
  getUser: 'user/get',
  getByIdUser: 'user/by_id',
  editUser: 'user/update',
  deleteUser: 'user/delete',
  forgetPassword: 'user/forgotPassword',
  changePassword: 'user/changePassword',
  login: 'user/signin',
  addMedium: 'medium/add',
  addStandard: 'standard/add',
  getMedium: 'medium/get',
  deleteMedium: 'medium/delete',
  getStandard: 'standard/get',
  getSubject: 'subject/get',
  addSubject: 'subject/add',
  editMedium: 'medium/update',
  editStandard: 'standard/update',
  editSubject: 'subject/update',
  deleteStandard: 'standard/delete',
  deleteSubject: 'subject/delete',
  mediumActiveDeactive: 'medium/active_deactive',
  standardActiveDeactive: 'standard/active_deactive',
  subjectActiveDeactive: 'subject/active_deactive',
  addAnimation: 'animation/add',
  editAnimation: 'animation/update',
  getAnimation: 'animation/get',
  byIdAnimation: 'animation/by_id',
  deleteAnimation: 'animation/delete',
  uploadImage: 'upload/single_file',
  uploadPdf: 'upload/single_pdf_file',
  addBackground: 'backgroundImg/add',
  getBackground: 'backgroundImg/get',
  getAnimationFilter: 'animation/filter',
  addBoard: 'boardImg/add',
  getBoard: 'boardImg/get',
  editBoard: 'boardImg/update',
  editBackground: 'backgroundImg/update',

  addWorkSheet: 'worksheet/add',
  getWorkSheet: 'workSheet/getWorkSheet',
  editWorkSheet: 'worksheet/update',
  byIdWorkSheet: 'worksheet/by_id',
  deleteWorkSheet: 'worksheet/delete',

  addActivityOne: 'activity/addActivityOne',
  getActivity: 'activity/getActivity',
  getByIdActivityOne: 'activity/by_idActivityOne',
  editActivityOne: 'activity/updateActivityOne',
  deleteActivityOne: 'activity/deleteActivityOne',
  addActitivtyNo: 'activityNo/add',
  getActivityNo: 'activityNo/get',
  getByIdActivityNo: 'activityNo/by_id',
  editActivityNo: 'activityNo/update',
  deleteActivityNo: 'activityNo/delete',
  addActivityTwo: 'activity/addActivityTwo',
  getActivityTwo: 'activity/getActivityTwo',
  getByIdActivityTwo: 'activity/by_idActivityTwo',
  editActivityTwo: 'activity/updateActivityTwo',
  deleteActivityTwo: 'activity/deleteActivityTwo',
  addActivityThree: 'activity/addActivityThree',
  editActivityThree: 'activity/updateActivityThree',
  getByIdActivityThree: 'activity/by_idActivityThree',
  deleteActivityThree: 'activity/deleteActivityThree',
  addActivityFour: 'activity/addActivityFour',
  getByIdActivityFour: 'activity/by_idActivityFour',
  editActivityFour: 'activity/updateActivityfour',
  deleteActivityFour: 'activity/deleteActivityFour',
  addActivityFive: 'activity/addActivityFive',
  getByIdActivityFive: 'activity/by_idActivityFive',
  editActivityFive: 'activity/updateActivityFive',
  deleteActivityFive: 'activity/deleteActivityFive',
  addActivitySix: 'activity/addActivitySix',
  getByIdActivitySix: 'activity/by_idActivitySix',
  editActivitySix: 'activity/updateActivitySix',
  deleteActivitySix: 'activity/deleteActivitySix',
  addActitivtySeven: 'activity/addActivitySeven',
  getByIdActivitySeven: 'activity/by_idActivitySeven',
  editActivitySeven: 'activity/updateActivitySeven',
  deleteActivitySeven: 'activity/deleteActivitySeven',
  addActitivtyEight: 'activity/addActivityEight',
  getByIdActivityEight: 'activity/by_idActivityEight',
  editActivityEight: 'activity/updateActivityEight',
  deleteActivityEight: 'activity/deleteActivityEight',
  addActivityNine: 'activity/addActivityNine',
  getByIdActivityNine: 'activity/by_idActivityNine',
  editActivityNine: 'activity/updateActivityNine',
  deleteActivityNine: 'activity/deleteActivityNine',
  ActivityNoFilter: 'activityNo/filter',
  addActivityTen: 'activity/addActivityTen',
  getByIdActivityTen: 'activity/by_idActivityTen',
  editActivityTen: 'activity/updateActivityTen',
  deleteActivityTen: 'activity/deleteActivityTen',
  getByIdActivityEleven: 'activity/by_idActivityEleven',
  addActivityEleven: 'activity/addActivityEleven',
  editActivityEleven: 'activity/updateActivityEleven',
  deleteActivityEleven: 'activity/deleteActivityEleven',
  addActivityTwelve: 'activity/addActivityTwelve',
  editActivityTwelve: 'activity/updateActivityTwelve',
  getByIdActivityTwelve: 'activity/by_idActivityTwelve',
  deleteActivityTwelve: 'activity/deleteActivityTwelve',
  addActivitySixteen: 'activity/addActivitySixteen',
  editActivitySixteen: 'activity/updateActivitySixteen',
  getByIdActivitySixteen: 'activity/by_idActivitySixteen',
  deleteActivitySixteen: 'activity/deleteActivitySixteen',
  addActivityNineteen: 'activity/addActivityNineteen',
  editActivityNineteen: 'activity/updateActivityNineteen',
  getByIdActivityNineteen: 'activity/by_idActivityNineteen',
  deleteActivityNineteen: 'activity/deleteActivityNineteen',
  addActitivtyEightteen: 'activity/addActivityEighteen',
  editActivityEightteen: 'activity/updateActivityEighteen',
  getByIdActivityEightteen: 'activity/by_idActivityEighteen',
  deleteActivityEightteen: 'activity/deleteActivityEighteen',
  getByIdActivityFifteen: 'activity/by_idActivityFifteen',
  addActivityFifteen: 'activity/addActivityFifteen',
  editActivityFifteen: 'activity/updateActivityFifteen',
  deleteActivityFifteen: 'activity/deleteActivityFifteen',
  getByIdActivitySeventeen: 'activity/by_idActivitySeventeen',
  addActivitySeventeen: 'activity/addActivitySeventeen',
  editActivitySeventeen: 'activity/updateActivitySeventeen',
  deleteActivitySeventeen: 'activity/deleteActivitySeventeen',
  getByIdActivityTwenty: 'activity/by_idActivityTwenty',
  addActivityTwenty: 'activity/addActivityTwenty',
  editActivityTwenty: 'activity/updateActivityTwenty',
  deleteActivityTwenty: 'activity/deleteActivityTwenty',
  getByIdActivityTwentyOne: 'activity/by_idActivityTwentyOne',
  addActivityTwentyOne: 'activity/addActivityTwentyOne',
  editActivityTwentyOne: 'activity/updateActivityTwentyOne',
  deleteActivityTwentyOne: 'activity/deleteActivityTwentyOne',
  addActivityTwentyFive: 'activity/addActivityTwentyFive',
  getByIdActivityTwentyFive: 'activity/by_idActivityTwentyFive',
  editActivityTwentyFive: 'activity/updateActivityTwentyFive',
  deleteActivityTwentyFive: 'activity/deleteActivityTwentyFive',
  addActitivtyTwentyEight: 'activity/addActivityTwentyEight',
  getByIdActivityTwentyEight: 'activity/by_idActivityTwentyEight',
  editActivityTwentyEight: 'activity/updateActivityTwentyEight',
  deleteActivityTwentyEight: 'activity/deleteActivityTwentyEight',
  getByIdActivityTwentyThree: 'activity/by_idActivityTwentyThree',
  addActivityTwentyThree: 'activity/addActivityTwentyThree',
  editActivityTwentyThree: 'activity/updateActivityTwentyThree',
  deleteActivityTwentyThree: 'activity/deleteActivityTwentyThree',
  addActitivtyTwentySeven: 'activity/addActivityTwentySeven',
  getByIdActivityTwentySeven: 'activity/by_idActivityTwentySeven',
  editActivityTwentySeven: 'activity/updateActivityTwentySeven',
  deleteActivityTwentySeven: 'activity/deleteActivityTwentySeven',

  addActivityTwentySix: 'activity/addActivityTwentySix',
  getByIdActivityTwentySix: 'activity/by_idActivityTwentySix',
  editActivityTwentySix: 'activity/updateActivityTwentySix',
  deleteActivityTwentySix: 'activity/deleteActivityTwentySix',

  addActitivtyThirteen: 'activity/addActivityThirteen',
  getByIdActivityThirteen: 'activity/by_idActivityThirteen',
  editActivityThirteen: 'activity/updateActivityThirteen',
  deleteActivityThirteen: 'activity/deleteActivityThirteen',

  addActitivtyFourteen: 'activity/addActivityFourteen',
  getByIdActivityFourteen: 'activity/by_idActivityFourteen',
  editActivityFourteen: 'activity/updateActivityFourteen',
  deleteActivityFourteen: 'activity/deleteActivityFourteen',

  addActitivtyTwentyTwo: 'activity/addActivityTwentyTwo',
  getByIdActivityTwentyTwo: 'activity/by_idActivityTwentyTwo',
  editActivityTwentyTwo: 'activity/updateActivityTwentyTwo',
  deleteActivityTwentyTwo: 'activity/deleteActivityTwentyTwo',

  addActitivtyTwentyFour: 'activity/addActivityTwentyFour',
  getByIdActivityTwentyFour: 'activity/by_idActivityTwentyFour',
  editActivityTwentyFour: 'activity/updateActivityTwentyFour',
  deleteActivityTwentyFour: 'activity/deleteActivityTwentyFour',

  editProfile: 'user/update/admin',
  getByIdProfile: 'user/by_Id/admin',

  addAplication: 'appVersion/addAppVersion',
  editAplication: 'appVersion/updateAppVersion',
  getAplication: 'appVersion/getAppVersion',

  getMainActivity: 'activityNo/getMain',
  editMainActivity: 'activityNo/updateMain',
  getByIdMainActivity: 'activityNo/by_idMain',
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { postLogin } from '../../../../redux/Actions/AuthUser'
import ErrorHandler from '../../../../lib/ErrorHandler'
import { ErrorToast, SuccessToast } from '../../../../lib/Toast'
import Loader from '../../../component/Loader'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required!'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [snackBar, setSnackBar] = useState('')
  const [show, setShow] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const body: any = {
        email: values?.email,
        password: values?.password,
        userType: 0,
      }

      const response: any = await dispatch(postLogin(body))

      const error = ErrorHandler(response)
      if (error) {
        setLoading(true)
        SuccessToast(response?.payload?.message)
        localStorage.setItem('userData', JSON.stringify(response?.payload?.data?.userDetail))
        localStorage.setItem('access_token', response?.payload?.data?.token)
        dispatch(auth.actions.user(response?.payload?.data?.token))
        history.push('/activity')
        setLoading(false)
      }
    },
  })

  const handleShow = () => {
    setShow(!show)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In Patram kids</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          {' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid'
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          </div>
        </div>
        <div className="input-group">
          <input type={show ? 'text' : 'password'} placeholder='Enter Password' {...formik.getFieldProps('password')} className="form-control form-control-lg form-control-solid" aria-label="Dollar amount (with dot and two decimal places)" />
          <span className="input-group-text border-0 form-control-solid" onClick={handleShow}>
            {
              !show ?
                <KTSVG
                  path={'/media/icons/duotune/general/eye.svg'}
                  className={'svg-icon-1 cursor-pointer'}
                />
                : <KTSVG
                  path={'/media/icons/duotune/general/closeEye.svg'}
                  className={'svg-icon-1 cursor-pointer'}
                />
            }
          </span>

        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container text-danger'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        <div className="text-end">
          <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bolder'
          >
            Forgot Password ?
          </Link>
        </div>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-label'>Continue</span>
        </button>
        {/* {loading && (
            // <div className='' style={{ position: 'absolute', background: 'red', height: '100vh' , top:'0', width:'100%' }}>
            <div className="loader">
              <i className="loader-el"></i>
              <i className="loader-el"></i>
            </div>
            // </div>
          )} */}

        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}

        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a>

        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a>

        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
      </div>
      <Loader open={loading} />
    </form>
  )
}

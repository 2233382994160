
const Loader = ({open}: any) => {
  return (
    open && (
      <div className='loader'>
        <i className='loader-el'></i>
        <i className='loader-el'></i>
      </div>
    )
  )
}

export default Loader

import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { checkIsActive, KTSVG } from '../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()

  return (
    <div className='menu-item me-lg-1'>
      {hasBullet && (
        <span className='menu-bullet'>
          <span className='bullet bullet-dot'></span>
        </span>
      )}

      {icon && (
        <span className='menu-icon'>
          <KTSVG path={icon} className='svg-icon-2' />
        </span>
      )}

      {fontIcon && (
        <span className='menu-icon'>
          <i className={clsx('bi fs-3', fontIcon)}></i>
        </span>
      )}

      <span className='menu-title text-white'>{title}</span>

      {hasArrow && <span className='menu-arrow'></span>}
    </div>
  )
}

export { MenuItem }

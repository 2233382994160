import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../redux/AuthCRUD'
import ErrorHandler from '../../../../lib/ErrorHandler'
import { useDispatch } from 'react-redux'
import { SuccessToast } from '../../../../lib/Toast'
import { changePassword } from '../../../../redux/Actions/AuthUser'

const initialValues = {
    otp: '',
    password: '',
    cPassword: '',

}

const forgotPasswordSchema = Yup.object().shape({
    otp: Yup.string()
        .min(6, 'Minimum 6 number')
        .max(6, 'Maximum 6 number')
        .required('OTP is required'),
    password: Yup.string()
        .required('Password is required').min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
    cPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords does not match'),
})

export function ChangePassword() {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const email: any = location?.state

    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [showTwo, setShowTwo] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const body: any = {
                otp: values?.otp,
                password: values?.password,
                email: email.email
            }
            const response: any = await dispatch(changePassword(body))
            const error = ErrorHandler(response)
            setLoading(false)
            if (error) {
                SuccessToast(response?.payload?.message)
                history.push('/auth/login')
            }
        },
    })
    const handleShow = (key: any) => {
        if (key === 'p') {
            setShow(!show)

        } else {
            setShowTwo(!showTwo)
        }
    }

    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    <h1 className='text-dark mb-3'>Change Password</h1>
                </div>

                <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
                    <input
                        type='number'
                        placeholder='Enter OTP'
                        autoComplete='off'
                        {...formik.getFieldProps('otp')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                        )}
                    />
                    {formik.touched.otp && formik.errors.otp && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                                <span role='alert'>{formik.errors.otp}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                    <div className="input-group">
                        <input type={show ? 'text' : 'password'} {...formik.getFieldProps('password')} placeholder='Enter Password' className="form-control form-control-lg form-control-solid" aria-label="Dollar amount (with dot and two decimal places)" />
                        <span className="input-group-text border-0 form-control-solid" onClick={() => handleShow('p')}> <i className={`${show ? 'bi bi-eye-slash' : 'bi bi-eye'} fs-2 cursor-pointer`}></i></span>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}

                </div>
                <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
                    <div className="input-group">
                        <input type={showTwo ? 'text' : 'password'} {...formik.getFieldProps('cPassword')} placeholder='Enter Confrim Password' className="form-control form-control-lg form-control-solid" aria-label="Dollar amount (with dot and two decimal places)" />
                        <span className="input-group-text border-0 form-control-solid" onClick={() => handleShow('c')}> <i className={`${showTwo ? 'bi bi-eye-slash' : 'bi bi-eye'} fs-2 cursor-pointer`}></i></span>
                    </div>
                    {formik.touched.cPassword && formik.errors.cPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                                <span role='alert'>{formik.errors.cPassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                    >
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-light-primary fw-bolder'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </button>
                    </Link>{' '}
                </div>
            </form>
        </>
    )
}

import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks } from '../../../partials'
import { useLayout } from '../../core'
import localStoreUtil from '../../../helpers/localstorage'
import ErrorHandler from '../../../../lib/ErrorHandler'
import { getByIdProfile } from '../../../../redux/Actions/AuthUser'
import { useDispatch } from 'react-redux'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = (isTrue: any) => {
  const dispatch = useDispatch()
  const [data, setData] = useState<any>({})

  const getById = async () => {
    let data: any = localStorage?.getItem('userData')
    let id: any = JSON.parse(data)
    const body = `${'?id=' + id?._id}`
    const response: any = await dispatch(getByIdProfile(body))
    const error = ErrorHandler(response)
    if (error) {
      setData(response?.payload?.data)
    }
  }

  useEffect(() => {
    getById()
  }, [isTrue])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol text-white', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          Hi, {data?.name}
          <img className='ms-2' src={data?.avtar ? data?.avtar : '/media/images/user.png'} alt='metronic' />
        </div>
        <HeaderUserMenu data={data} />
        {/* end::Toggle */}
      </div>
    </div>
  )
}

export { Topbar }

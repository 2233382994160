import React from 'react'

interface modalProtoTypes {
  title?: string
  children: any
  modal?: any
  setModal?: any
  handleClose?: any
  isSearch?: any
}

export const CommonModal = ({
  setModal,
  modal,
  children,
  title,
  handleClose,
  isSearch,
}: modalProtoTypes) => {
  return (
    <>
      <div
        className={`modal fade ${modal ? 'show' : ''}`}
        id='exampleModal'
        aria-labelledby='exampleModalLabel'
        aria-modal={modal ? `true` : `false`}
        style={{display: `${modal ? 'block' : 'none'}`, background: 'rgba(0,0,0,0.6)'}}
        role={modal && 'dialog'}
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-4' id='exampleModalLabel'>
                {title}
              </h1>
              <button
                type='button'
                className='btn-close'
                onClick={() => handleClose()}
                // data-bs-dismiss='modal'
                // aria-label='Close'
              ></button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

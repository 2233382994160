/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import localStoreUtil from '../../../helpers/localstorage'
import { toAbsoluteUrl } from '../../../helpers'

const HeaderUserMenu: FC = (data: any) => {

  const logout = () => {
    localStorage.clear()
    window.location.href = '/auth/login'
  }

  return (

    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img className='ms-2' src={data?.data?.avtar ? data?.data?.avtar : '/media/images/user.png'} alt='metronic' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {data?.data?.name}
            </div>
            <a href='#' className='fw-bold text-muted fs-7'>
              {data?.data?.email}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item fs-5'>
        <Link to='/profile' className='menu-link px-5'>
          Profile
        </Link >
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item fs-5'>
        <Link to='/logout' className='menu-link px-5' onClick={logout}>
          Sign Out
        </Link>
      </div>
    </div>

  )
}

export { HeaderUserMenu }

import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import Application from '../pages/Application'

export function PrivateRoutes() {
  const Users = lazy(() => import('../pages/users'))
  const Meduim = lazy(() => import('../pages/medium'))
  const Standard = lazy(() => import('../pages/standard'))
  const Subject = lazy(() => import('../pages/subject'))
  const Activity = lazy(() => import('../pages/activity'))
  const Animation = lazy(() => import('../pages/animation'))
  const Images = lazy(() => import('../pages/images/background'))
  const Board = lazy(() => import('../pages/images/board'))
  const ActivityNo = lazy(() => import('../pages/activityNo'))
  const MainActivityNo = lazy(() => import('../pages/MainActivityNo'))
  const WorkSheet = lazy(() => import('../pages/worksheet'))
  const Profile = lazy(() => import('../pages/profile'))


  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/users' component={Users} />
        <Route path='/medium' component={Meduim} />
        <Route path='/standard' component={Standard} />
        <Route path='/subject' component={Subject} />
        <Route path='/activity' component={Activity} />
        <Route path='/animation' component={Animation} />
        <Route path='/image/image' component={Images} />
        <Route path='/image/board' component={Board} />
        <Route path='/no' component={ActivityNo} />
        <Route path='/main' component={MainActivityNo} />
        <Route path='/worksheet' component={WorkSheet} />
        <Route path='/profile' component={Profile} />
        <Route path='/application' component={Application} />

        <Redirect from='/auth' to='/activity' />
        <Redirect exact from='/' to='/activity' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

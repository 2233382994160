const CommonButton = (props: any) => {
  return (
    <button
      className={`btn btn-white btn-active-primary ${!props?.isMargin && 'me-2'} mb-3 text-white`}
      style={{
        background: props?.isClear
          ? 'linear-gradient(180deg, #FF0000 0.24%, #990101 96.59%)'
          : 'linear-gradient(180deg, #00C9FF 0.24%, #0059E1 96.59%)',
        width: props?.width,
      }}
      onClick={props?.onClick}
      disabled={props?.disabled}
      type={props?.type || 'submit'}
      // data-bs-toggle={props?.dataBsToggle}
      // data-bs-target={props?.dataBsTarget}
      // data-bs-dismiss={props?.dataBsDismiss}
    >
      {props?.text}
    </button>
  )
}

export default CommonButton

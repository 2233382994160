const CommonTextField = (props: any) => {
  return (
    <>
      {props?.label && (
        <label className={`col-lg-12 col-form-label fw-bold fs-6 ${props?.disabled ? '' : 'required'}`}>{props?.label}</label>
      )}
      <input
        min={props?.min}
        max={props?.max}
        placeholder={props?.placeholder}
        type={props?.type || 'text'}
        name={props?.name}
        checked={props?.checked}
        value={props?.value}
        id={props?.id}
        onChange={props?.onChange}
        disabled={props?.disabled}
        onKeyDown={props?.onKeyDown}
        onWheel={props?.onWheel ? props?.onWheel : (e: any) => e.target.blur()}
        className={`form-control form-control-lg ${props?.disabled ? 'form-control-dark' : 'form-control-solid'} mb-3 mb-lg-0 ${props?.className}`}
      />
    </>
  )
}

export default CommonTextField

import { responseEnum } from './enum'
import { ErrorToast } from './Toast'
export default function ErrorHandler(data: any) {
  if (data?.payload?.status) {
    if (data?.payload?.status === responseEnum.InternalServerCode) {
      ErrorToast(data?.payload?.message)
      return false
    } else if (data?.payload?.status === responseEnum.ValidationCode) {
      ErrorToast(data?.payload?.message)
      return false
    } else if (data?.payload?.status === responseEnum.ValidationCode2) {
      ErrorToast(data?.payload?.message)
      return false
    }else if (data?.payload?.status === responseEnum.ValidationCode3) {
      ErrorToast(data?.payload?.message)
      return false
    }  else if (data?.payload?.status === responseEnum.UnAuth) {
      ErrorToast(data?.payload?.message)
      localStorage.clear()
      window.location.href = '/'
      return false
    } else {
      return true
    }
  } else {
    ErrorToast('Something went wrong!')
    return false
  }
}
